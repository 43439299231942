import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"


class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="All posts" />
            <p>Zara Houshmand is an Iranian American writer, editor, and literary translator whose work crosses boundaries and cultural divides. She lives in the mountains of California.</p>
        {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            let thumbimage
            if (node.frontmatter.featuredImage !== null){
                const featuredImgFluid = node.frontmatter.featuredImage.childImageSharp.fluid
                thumbimage =                     <div style={{
                    //textAlign: 'center'
                }}
                    >
                    <div style={{
                        margin: '0 auto',
                        //display: 'inline-block',
                        width: '30%',
                    }}
                    >
                    <Img fluid={featuredImgFluid} /><br/>
                    </div>
                    </div>

            }
            else{
                thumbimage = "";
            }
            return (
            <div key={node.fields.slug}>
                  <h1

                style={{
                  marginBottom: rhythm(1 / 4),
                }}
                    >
		      {title !== "bio" &&
                       <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
			{thumbimage}
			{title}
                       </Link>
		      }
              </h1>

              <p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </div>
          )
        })}
      <h1><a href="https://docs.google.com/forms/d/e/1FAIpQLSfjmwP0eLtyjB-Hz_M-mXOtx5O5zsXeTfKSzrzxV7HA6WZIqw/viewform?usp=sf_link"> Contact </a></h1>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 600, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

import Typography from "typography"
import Wordpress2016 from "typography-theme-wordpress-2016"

const hface = 'Philosopher';

//const hface = 'Arya';
//const hface = 'Spectral';
const bface = 'Belleza';
//or roboto...
const scaleR = 2.0;
Wordpress2016.googleFonts.push(
    {name: bface, styles: [400, 700]}
);
Wordpress2016.googleFonts.push(
    {name: hface, styles: ['Regular']}
);
Wordpress2016.overrideThemeStyles = ({ rhythm },options) => {
  return {
      'a.gatsby-resp-image-link': {
      boxShadow: `none`,
    },
    'h1, h2': {
          fontFamily: hface
    },
      //note the space after the h4, this is a hack to deal with a bug in typography.js.
      'h4 ': {
          marginTop: rhythm(0),
          marginBottom: rhythm(1.25),
      },
      'body': {

        background: '#f0f0f0',
      },
/*
      'p::first-line': {
         textIndent: '2em',
      },
         */
      'div.z > p:nth-last-child(n+2)': {
          marginBottom: '0',
      },
      'div.z > p:first-child': {
          textIndent: '0em',
      },
      'div.z > p': {
          textIndent: '2em',
      },

      'blockquote': {
          borderLeft: 'none',
          marginBottom: rhythm(.5),
          //background: '#fff',
          //fontFamily: bface,
          //fontStyle: 'normal',
      },
      'emphasis': {
          fontStyle: 'normal',
      },

     'a:hover, a:visited, a:link, a:active ': {
         color: '#889988',
         textDecoration: 'none',
         boxShadow: 'none',
     },
      'pre': {
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word',

      },
      'code': {
          fontSize: '1rem',
//          lineHeight: '1rem',
          display: 'inline-block',
          paddingLeft: rhythm(1/2),
          paddingRight: rhythm(1/2),
          paddingTop: rhythm(1/4),
          paddingBottom: rhythm(1/4),
          marginLeft: rhythm(-1/8),
          //borderLeft: `${rhythm(2 / 16)} solid #ded`,
          //borderRight: `${rhythm(2 / 16)} solid #ded`,
          fontFamily: bface,
          background: '#fff',
          wordWrap: 'break-word',
      },
  }
}


Wordpress2016.bodyFontFamily = [bface, 'sans-serif'];
Wordpress2016.headerFontFamily = [hface, 'sans-serif'];
//Wordpress2016.headerGray = '25%';
Wordpress2016.bodyColor = "hsla(0,0%,20%,.9)";
Wordpress2016.headerColor = "hsla(0,0%,10%,.9)";
//Wordpress2016.baseLineHeight = 1.5;
Wordpress2016.scaleRatio = scaleR;

//delete Wordpress2016.googleFonts
console.log(Wordpress2016);
const typography = new Typography(Wordpress2016)


// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
